// start_ai_generated
import { useCommerce } from '@shopify'
import { useEffect, useState } from 'react'
import type {
  Product,
  ProductCoupon,
  ProductVariant,
  VariantCoupon,
} from '@commerce/types/product'
import { formatCurrency } from '@lib/utils'
import { useRouter } from 'next/router'

interface CouponsInfoProp {
  product?: Product
  variant?: ProductVariant
  coupon?: ProductCoupon | VariantCoupon
  isDigits?: boolean
}

function useCounponInfo({
  coupon,
  product,
  variant,
  isDigits,
}: CouponsInfoProp) {
  const { shop } = useCommerce()
  const { locale } = useRouter()
  const [priceFormatted, setPriceFormatted] = useState('')
  const [discountText, setDiscountText] = useState('')
  const [discountVal, setdiscountVal] = useState(0)
  const [discountPriceFormatted, setDiscountPriceFormatted] = useState('')
  const [discountPriceWithOutSymbol, setDiscountPriceWithOutSymbol] =
    useState('')

  const [priceWithOutSymbol, setPriceWithOutSymbol] = useState('')
  const moneyFormat = shop.moneyFormat

  useEffect(() => {
    const price = variant?.price || product?.price?.value || 0
    setPriceWithOutSymbol(formatCurrency(moneyFormat, price, false, true))
    setPriceFormatted(formatCurrency(moneyFormat, price))

    if (coupon) {
      const { variant_price4wscode, value_type, fixed_value, value } = coupon

      if (value_type === 'percentage') {
        const val = Math.abs(parseInt(value))
        setDiscountText(`${val}${locale === 'eu-fr' ? ' ' : ''}%`)
        setDiscountPriceFormatted(
          formatCurrency(moneyFormat, variant_price4wscode)
        )
      } else if (value_type === 'fixed_amount') {
        setDiscountText(formatCurrency(moneyFormat, +fixed_value, isDigits))
        setDiscountPriceFormatted(
          formatCurrency(moneyFormat, variant_price4wscode)
        )
      }

      setdiscountVal(Math.floor((+price - +variant_price4wscode) * 100) / 100)
      setDiscountPriceWithOutSymbol(
        formatCurrency(moneyFormat, variant_price4wscode, true, true)
      )
    } else {
      setDiscountPriceFormatted('')
      setDiscountText('')
      setdiscountVal(0)
      setDiscountPriceWithOutSymbol('')
    }
  }, [coupon, product, variant, isDigits, moneyFormat, locale])

  return {
    priceFormatted,
    priceWithOutSymbol,
    discountPriceFormatted,
    discountText,
    discountVal,
    discountPriceWithOutSymbol,
  }
}

export default useCounponInfo
